import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import MissingPage from "../images/404-page.jpg"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import styled from "styled-components"

const InnerPage = styled.div`
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin-left: -14px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: url(${MissingPage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  img {
    margin: 0 auto;
    display: block;
  }

  @media only screen and (max-width: 767px) {
    height: 65vh;
    background-size: cover;
    background-position: bottom;
    margin-bottom: 4rem;
  }
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1.3rem;
  margin-top: 10rem;
  margin-bottom: 2rem;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  :hover {
    background-color: rgb(244, 203, 50);
    color: #000;
    transition: all 0.4s;
  }

  a {
    color: #fff;
  }
`

export default function NotFound() {
  return (
    <Layout>
      <InnerPage>
        <Button>
          <AniLink cover to="/home" direction="up" duration={1.5} bg="#ffcf40">
            Go Back Home
          </AniLink>
        </Button>
      </InnerPage>
    </Layout>
  )
}
